.Menu {
    overflow: hidden;
    background-color: #2557c4;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    z-index: 5;
}

.Menu li {
    display: inline-block;
    padding: 14px 16px;
}

.menu-link {
    text-align: center;
    color: #f2f2f2;
    padding: 14px 16px;
    text-decoration: none;
    cursor: pointer;
}

.Menu ul {
    padding: 0;
}

.menu-link:hover {
    background: #ffd618;
    color: black;
}