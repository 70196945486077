.Footer{
    height: 100%;
    background-color: #0f3381;
    color: white;
    font-size: 2vh;
    overflow: hidden;
    flex: 0 1 100%;
}

.Footer a{
    color: white;
    text-decoration: none;
    cursor: url("./images/email.png") 20 20, wait;
}

.Footer ul{
    padding: 0;
}

.Footer li{
    padding: 5px 5px;
    list-style: none;
}

.Footer a:visited{
    color: white;
}

.Contact a{
    text-decoration: underline;
}

.AGB a{
    text-decoration: underline;
}