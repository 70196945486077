@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 2vh;
  z-index: 100;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2557c4;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffd618;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555555;
}