.App {
  text-align: center;
  background-color: #ffd618;
  color: #2557c4;
  font-size: 2.5vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.LazyLoad {
  flex: 0 1 100%;
  text-align: center;
  min-height: 100vh;
  height:auto;
  width: 100%;
}

.paralaxBG {
  align-items: center;
  min-height: 100vh;
  height: 100%;
  display: flex;
}

.insideBlock{
  align-items: center;
  background: none;
  color: white;
  margin: auto;
  height: 100%;
  padding: 3vh;
}

.Content{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
  height: 100%;
  border-radius: 10%;
}

.start .Content{
  height: 50vh;
  width: 50vh;
  border-radius: 50%;
}

.first{
  font-size: 7vh;
}

.second{
  font-size: 4vh;
}

.Media{
  width: 50%;
  height: auto;
  padding: 10vh;
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .Media{
    width: 80%;
    padding: 0%;
  }
  
  .Frame{
    width: 80%;
    padding: 0%;
  }

  .paralaxBG{
    /* height: 800px; */
    height: 100%;
  }

  .Content{
    width: 100%;
    height: 100%;
    border-radius: 0%;
  }
}

/* Paralax */
@media (height: 720px) {
  .paralaxBG{
    height: 720px;
  }
}
@media (height: 900px) {
  .paralaxBG{
    height: 900px;
  }
}
@media (height: 1080px) {
  .paralaxBG{
    height: 1080px;
  }
}
@media (height: 1152px) {
  .paralaxBG{
    height: 1152px;
  }
}
@media (height: 1440px) {
  .paralaxBG{
    height: 1440px;
  }
}
@media (height: 2160px) {
  .paralaxBG{
    height: 2160px;
  }
}